<picture>
    <source [srcset]="images().mobile" media="(max-width: 767px)" />
    <source [srcset]="images().tablet" media="(min-width: 768px) and (max-width: 1023px)" />
    <source [srcset]="images().desktop" media="(min-width: 1024px)" />
    <img
        [ngClass]="[classList() ?? '', ignoreHeight() ? '' : 'h-auto', cover() ? 'object-cover' : '', 'max-w-full']"
        [loading]="priority() ? 'eager' : 'lazy'"
        [attr.fetchpriority]="priority() ? 'high' : 'low'"
        [width]="image().mobile.width"
        [height]="image().mobile.height"
        [src]="images().desktop"
        [alt]="alt()"
    />
</picture>
