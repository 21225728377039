import { DOCUMENT, isPlatformServer, NgClass } from '@angular/common';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    inject,
    input,
    PLATFORM_ID,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { CLOUDINARY_IMAGE_CONFIG } from '@hyundai/ng-common-lib';
import type { HyundaiImage, ImageSize } from './m000-c010-image.interface';
import { ImageAiFocus } from './m000-c010-image.interface';

@Component({
    selector: 'ui-m000-c010-image',
    standalone: true,
    imports: [NgClass],
    templateUrl: './m000-c010-image.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M000C010ImageComponent {
    protected readonly renderer2 = inject(Renderer2);
    protected readonly document = inject(DOCUMENT);
    protected readonly platformId = inject(PLATFORM_ID);
    protected readonly config = inject(CLOUDINARY_IMAGE_CONFIG);

    protected readonly FALLBACK_IMAGE = '_static/fallback-image';

    image = input.required<HyundaiImage>();
    alt = input.required<string>();
    priority = input.required({ transform: booleanAttribute });
    title = input<string>();

    classList = input<string>();
    errorImageUrl = input<string>();
    ignoreHeight = input(false, { transform: booleanAttribute });
    cover = input(false, { transform: booleanAttribute });
    highRes = input(false, { transform: booleanAttribute });
    focusAi = input<ImageAiFocus>();
    contain = input(false, { transform: booleanAttribute });

    constructor() {
        effect(() => {
            const priority = this.priority();
            const images = this.image();
            const imagesLinks = this.images();
            if (priority && images && imagesLinks && isPlatformServer(this.platformId)) {
                const createPreloadLink = (href: string, media: string) => {
                    const link = this.renderer2.createElement('link');
                    link.setAttribute('href', href);
                    link.setAttribute('media', media);
                    link.setAttribute('rel', 'preload');
                    link.setAttribute('as', 'image');
                    this.renderer2.appendChild(this.document.head, link);
                };

                createPreloadLink(imagesLinks.mobile, '(max-width: 767px)');
                createPreloadLink(imagesLinks.tablet, '(min-width: 768px) and (max-width: 1023px)');
                createPreloadLink(imagesLinks.desktop, '(min-width: 1024px)');
            }
        });
    }

    images = computed(() => {
        const d = this.image();
        return {
            desktop: this.getCloudinaryImage(d.image, d.desktop),
            tablet: this.getCloudinaryImage(d.image, d.tablet),
            mobile: this.getCloudinaryImage(d.image, d.mobile),
        };
    });

    protected getCloudinaryImage(image: string | undefined, size: ImageSize): string {
        if (!image) {
            image = this.FALLBACK_IMAGE;
        }
        const isExternal = image.startsWith('http');
        const transformations = this.getCloudinaryProperties(size);
        return `${this.config.baseUrl}/image/${isExternal ? 'fetch' : 'upload'}/${transformations.join(',')}/q_80,f_auto/${image}`;
    }

    protected getCloudinaryProperties(size: ImageSize): string[] {
        const transformations: string[] = [`w_${size.width}`];
        if (size.height) {
            transformations.push(`h_${size.height}`);
        }

        if (this.focusAi() === ImageAiFocus.FACE) {
            transformations.push('c_fill', 'g_face');
        } else if (this.focusAi() === ImageAiFocus.VEHICLE) {
            transformations.push('c_fill', 'g_region_!vehicle!');
        } else if (this.cover()) {
            transformations.push('c_fill');
        } else if (this.contain()) {
            transformations.push('c_pad');
        } else if (this.ignoreHeight()) {
            transformations.push('c_crop');
        }

        if (this.highRes()) {
            transformations.push('dpr_2.0');
        }

        return transformations;
    }
}
