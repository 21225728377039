export interface HyundaiImage {
    image: string;
    desktop: ImageSize;
    tablet: ImageSize;
    mobile: ImageSize;
}

export interface ImageSize {
    width: number;
    height?: number;
}

export enum ImageAiFocus {
    VEHICLE,
    FACE,
}
